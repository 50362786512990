// theme variables
$primary: rgb(181, 141, 121);
$secondary: rgb(0, 0, 0);
$custom-colors: (
    "accent": #E4EAE4
);

// required bootstrap stuff
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

@import "../../node_modules/bootstrap/scss/maps";
$theme-colors: map-merge($theme-colors, $custom-colors);


@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';

// base bootstrap
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/forms";

@import "../../node_modules/bootstrap/scss/utilities/api";

@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/transitions";

* {
    font-family: inherit;
}

body {
    font-family: 'Poppins', sans-serif;
}

a.nav-link {
    text-decoration: none;
    font-weight: 400;
    padding: 0 .75rem;
    color: #fff;
    text-shadow: 2px 2px 5px rgb(0 0 0 / 25%);
}
.navbar-nav {
    gap: 1rem;
}
#navbar {
    z-index: 100;
    transition: background-color 250ms ease-in-out;
}
footer {
    padding: 2rem 0;
    #footer-logo {
        width: 300px;
        height: auto;
    }
    ul {
        padding-left: 1rem;
        list-style-image: url("../../public/assets/icons/caret.svg");
        li {
            margin-top: .5rem;
        }
        a {
            text-decoration: none;
            color: var(--bs-secondary);
            font-weight: 400;
        }
    }
    #footer-social-links {
        gap: .35rem;
        a > svg {
            width: 35px;
            height: 35px;
        }
    }
}

section.container-fluid {
    padding: 30px;
}

#match-me {
    background-color: tint-color($primary, 25);
}

.space {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.span-detail {
    font-size: 15px;
}

.no-decoration-link {
    text-decoration: none !important;
    text-underline: none !important;
    color: #1a1e21;
}
